.optionContainer3 {
  display: flex;
  margin-left: 53em;
  margin-top: -18em;
  gap: 20px;
  position: fixed;
}

.test3 {
  width: 50px;
  cursor: pointer;
}

.testModal3 {
  position: fixed;
  margin-top: -18rem;
  width: 750px;
}

.btnClosed3 {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 28px;
  height: 45px;
}

.btnClosed3:hover {
  color: white;
}

.download3 {
  margin-top: 8px;
}

.download3 a {
  font-size: 20px;
  color: white;
  text-decoration: none;
}

.download3 a:hover {
  color: white;
}

@media screen and (max-width: 800px) {
  .testModal3 {
    position: fixed;
    margin-top: -12rem;
    width: 372px;
  }

  .optionContainer3 {
    display: flex;
    margin-left: 130px;
    margin-top: -360px;
    position: fixed;
  }
}
