.container_Share3 {
  color: black;
  font-size: 26px;
}

.share3 {
  border: none;
}

.Toggleicon3 {
  width: 50px;
  background: transparent !important;
  border: none;
}

.icon3 {
  color: white;
  font-size: 25px;
  height: 40px;

}

.icon3:hover {
  color: rgb(253, 237, 237);
}

.iconFacebook3 {
  border: none;
  border-radius: 30px;
  width: 25px;
}

.iconTwitter3 {
  border: none;
  border-radius: 30px;
  width: 25px;
}

.iconPin3 {
  border: none;
  border-radius: 30px;
  width: 25px;
}
