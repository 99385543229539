.formContainer30 {
  margin-top: -70px;
  width: 450px;
  height: auto;
  box-shadow: 0px 0px 0px 1px white;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 100px;
  margin-left: 400px;
}
.formContainer30 form {
  justify-content: center;
  display: grid;

}
.inputContainer30 {
  margin-bottom: 40px;
}

.formContainer30 form input {
  margin-top: 10px;
  height: 45px;
  width: 22em;
  margin-left: 40px;
}
.btnCard30 {
  width: 40px;
  margin-left: 22em;
  margin-top: -85px;
  height: 45px;
  border: none;
  background-color: rgb(152, 152, 152);
  border-radius: 5px;
}

.formContainer30 form input::placeholder {
  font-size: 14px;
  color: rgb(130, 130, 130);
}

.inputCard {
  background-color: rgb(240, 240, 240);
  border: 0;
  outline: none;
}

.title {
  font-size: 20px;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: 20px;
}
@media screen and (max-width: 800px)  {  
  .formContainer30 {
    display: grid;
    margin-left:5px;
    width: 402px;

   
  }

  .formContainer30 form input {
    outline: none;
    width: 260px;
    margin-left: 80px;

  }
  .btnCard30 {
    margin-left: 302px;
    margin-top:-85px;
    border-radius: 5px;
    height: 45px;
  }
  .inputContainer {
    justify-content: center;
    display: grid;
  }
}

