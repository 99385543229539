.Register-Container {
  padding: 5em;
}


.Input-Container {
  box-shadow: 0px 0px 4px;
  display: grid;
  margin-top: 40px;
  padding: 1em;
  width: 50%;
}

.Input-Container input {
  width: 300px;
  margin-bottom: 20px;
}

.Input-Container p {
  margin-top: 10px;
}

.Input-Container2 {
  display: grid;
  margin-top: 40px;
  padding: 1em;
}
.button-Register {
  width: 300px;
  height: 40px;
  margin-top: 45px;
}

.button-Register:hover {
  opacity: 0.7;
}

.select-file {
}

.image-preview img {
  width: 130px;
}

.file-input {
  display: none; /* Oculta el input de tipo file */
}

.file-label {
  /* Estilos opcionales para mostrar un texto personalizado en lugar del nombre del archivo */
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

/* Estilos para mostrar un cursor diferente cuando se pasa sobre el texto personalizado */
.file-label:hover {
  background-color: #f0f0f0;
}


@media (max-width:768px) {

  .Input-Container {
    display: grid;
    justify-content: center;
    box-shadow: none;
    
  }
}