.target {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 250px;
}

.thead {
  background: rgb(3, 213, 3);
  color: white;
  margin-top: 30px;
}

.card {
  border-radius: 20px;
  width: 450px;
  height: 200px;
  display: grid;
}

.home {
  margin-top: -70px;
}
.home a {
  color: white;
  text-decoration: none;
}

.home:hover {
  opacity: 0.7;
}

.table_target {
  width: 400px;
  margin-left: 12px;
}

@media screen and (max-width: 800px) {

  .table_target {
    margin-top: -30px;
    display:grid;
    width: 300px;
    margin-left: 40px;
  }

  .home {
    margin-top: 50px;
  }
}
