.Register_Container {
  margin-top: 5em;
}

.Register_Container form {
  display: grid;
}

.Input_Container {
  box-shadow: 0px 0px 4px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, 400px);

  justify-content: center;
}

.Input_Container input {
  width: 300px;
  margin-bottom: 20px;
}

.Input_Container p {
  text-align: center;
  margin-top: 10px;
}

.bg_button {
  background-color: rgb(185, 101, 6);
  border: none;
  max-width: 300px;
  max-height: 40px;
  margin-top: 40px;
  margin-bottom: 50px;
}

.bg_button:hover {
  opacity: 0.7;
  background-color: rgb(185, 101, 6);
}

@media screen and (max-width: 768px) {
  .Input_Container {
    box-shadow: none;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fit, 400px);

    justify-content: center;
  }
}
